import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Select } from 'antd'
import { map } from 'lodash-es'

import {
  createUrl,
  getQueryParams,
} from '../../core/utils/services/queryParams'

import { BOOLEAN_TYPES, DEFAULT_PAGE } from '../constants'

const TaskIsRerunFlowFilter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { isRerunFlow } = getQueryParams(search)

  const handleChange = useCallback(
    isRerunFlow =>
      history.push(
        createUrl(pathname, search, {
          isRerunFlow,
          page: DEFAULT_PAGE,
        }),
      ),
    [history, pathname, search],
  )

  return (
    <Form.Item label="Rerun flow">
      <Select
        style={{ width: '100%' }}
        value={isRerunFlow?.toString()}
        onChange={handleChange}
        allowClear
      >
        {map(BOOLEAN_TYPES, ({ name, value }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default TaskIsRerunFlowFilter
