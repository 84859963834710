import React, { useCallback } from 'react'
import ReactJson from 'react-json-view'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Popover, Tooltip, Typography } from 'antd'
import { filter, findIndex, map, size, split } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'

import { LondonDateTime } from '../../../../common/components'
import { ADMIN } from '../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../../common/hooks'

import {
  CLEANUP,
  DONE,
  MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE,
  MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER,
  PREPARATION,
  SCRAPING,
  TEMPLATE_VALIDATION,
} from '../../../../menus/constants'
import {
  COMPLETE_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP,
  RESTART_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP,
} from '../../../graphql/mutations'
import { formatRerunFlowSteps } from '../../../helpers'

import {
  RerunFlowStepIcon,
  RerunFlowStepLine,
} from './menu-scraping-cluster-steps-indicator'

const MenuScrapingClusterRerunFlowStepsIndicator = ({
  menuScrapingCluster,
  refetchMenuScrapingCluster,
}) => {
  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const steps = menuScrapingCluster.rerunFlow?.steps

  const currentStep = menuScrapingCluster.rerunFlow?.currentStep || {
    type: PREPARATION,
  }

  const currentStepOrder = findIndex(
    MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER,
    t => t === currentStep.type,
  )

  const formatTooltipContent = info => {
    const listItems = map(split(info, '\n'), item => <li key={item}>{item}</li>)
    return <ul>{listItems}</ul>
  }

  const formattedSteps = formatRerunFlowSteps(steps, currentStep)

  const [restartStep, { loading: isResetInProgress }] = useMutation(
    RESTART_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP,
    {
      onCompleted() {
        refetchMenuScrapingCluster()
        showSuccessNotification({
          message: 'Success',
          description: 'Step restarted successfully.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Failed to restart the step',
          description: message,
        })
      },
    },
  )

  const [completeStep, { loading: isCompleteInProgress }] = useMutation(
    COMPLETE_MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP,
    {
      onCompleted() {
        showSuccessNotification({
          message: 'Success',
          description: 'Step completed successfully.',
        })
        refetchMenuScrapingCluster()
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Failed to complete the step',
          description: message,
        })
      },
    },
  )

  const handleRestartStep = useCallback(
    type => {
      const onOk = () =>
        restartStep({
          variables: {
            rerunFlowId: menuScrapingCluster.rerunFlow.id,
            stepType: type,
          },
        })

      Modal.confirm({
        title: 'Are you sure you want to restart a template validation step?',
        content:
          'All menu scraping sessions in progress will be aborted automatically.',
        onOk,
      })
    },
    [menuScrapingCluster, restartStep],
  )

  const handleCompleteStep = useCallback(
    type => {
      const onOk = () =>
        completeStep({
          variables: {
            rerunFlowId: menuScrapingCluster.rerunFlow.id,
            stepType: type,
          },
        })

      if (type === SCRAPING) {
        Modal.confirm({
          title: 'Are you sure you want to complete this step?',
          content: 'This will approve the menus, which may take a few seconds.',
          onOk,
        })
      } else if (type === CLEANUP) {
        Modal.confirm({
          title: 'Are you sure you want to complete this step?',
          content:
            'This action will move the flow to the "Done" step, and you will not be able to go back.',
          onOk,
        })
      } else {
        onOk()
      }
    },
    [menuScrapingCluster, completeStep],
  )

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '24px 0',
      }}
    >
      {map(formattedSteps, (step, i) => {
        const stepDetails =
          MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEP_TYPE[step.type]

        const isCurrentStep = step.type === currentStep.type && step.startedDate

        const stepOrder = findIndex(
          MENU_SCRAPING_CLUSTER_RERUN_FLOW_STEPS_ORDER,
          t => t === step.type,
        )

        const isFinishedStep =
          step.finishedDate &&
          (stepOrder < currentStepOrder || step.type === DONE)

        let buttonText
        let buttonOnClick

        if (isCurrentStep && step.type !== DONE) {
          buttonText = 'Done'
          buttonOnClick = () => handleCompleteStep(step.type)
        } else if (
          step.type === TEMPLATE_VALIDATION &&
          currentStep.type === SCRAPING
        ) {
          buttonText = 'Restart'
          buttonOnClick = () => handleRestartStep(step.type)
        }

        const allStepsOfTheSameType = map(
          filter(steps, s => s.type === step.type),
          s => ({
            startedDate: s.startedDate,
            finishedDate: s.finishedDate,
            abortedDate: s.abortedDate,
          }),
        )

        return (
          <div
            key={step.type}
            style={{
              display: 'flex',
              minWidth: '20%',
              zIndex: 1,
              alignItems: 'stretch',
            }}
          >
            <RerunFlowStepIcon
              isCurrentStep={isCurrentStep}
              isFinishedStep={isFinishedStep}
              order={i}
            />
            <div
              style={{
                marginTop: '6px',
                paddingRight: '12px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text
                style={{
                  marginBottom: '6px',
                  fontWeight:
                    isCurrentStep && !isFinishedStep ? 'bold' : 'normal',
                }}
              >
                <Tooltip
                  title={formatTooltipContent(stepDetails.info)}
                  trigger="hover"
                  placement="topRight"
                  overlayClassName="menu-scraping-cluster-rerun-flow-indicator-step-tooltip"
                >
                  {stepDetails.name}
                </Tooltip>
                {size(allStepsOfTheSameType) > 1 && (
                  <Popover
                    placement="top"
                    content={
                      <ReactJson
                        src={allStepsOfTheSameType}
                        style={{ minWidth: '500px' }}
                        enableClipboard={false}
                        displayDataTypes={false}
                        name={false}
                      />
                    }
                  >
                    <Button
                      size="small"
                      shape="circle"
                      style={{ marginLeft: '12px' }}
                    >
                      {size(allStepsOfTheSameType)}
                    </Button>
                  </Popover>
                )}
              </Typography.Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {step.startedDate && step.type !== DONE && (
                  <Typography.Text>
                    Started <LondonDateTime date={step.startedDate} />
                  </Typography.Text>
                )}
                {step.finishedDate && (
                  <Typography.Text>
                    Finished <LondonDateTime date={step.finishedDate} />
                  </Typography.Text>
                )}
                {step.abortedDate && (
                  <Typography.Text
                    style={{
                      color: '#ff4d4f',
                    }}
                  >
                    Aborted <LondonDateTime date={step.abortedDate} />
                  </Typography.Text>
                )}
              </div>

              <Button
                disabled={
                  (!isLoggedInUser && !isAdmin) ||
                  isCompleteInProgress ||
                  isResetInProgress ||
                  step.type === SCRAPING
                }
                loading={
                  (isCompleteInProgress && currentStep.type === step.type) ||
                  (isResetInProgress && step.type === TEMPLATE_VALIDATION)
                }
                type={isFinishedStep ? 'default' : 'primary'}
                style={{
                  marginTop: '12px',
                  opacity: buttonOnClick ? 1 : 0,
                  cursor: buttonOnClick ? 'pointer' : 'default',
                }}
                onClick={buttonOnClick}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        )
      })}
      <RerunFlowStepLine />
    </div>
  )
}

MenuScrapingClusterRerunFlowStepsIndicator.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  refetchMenuScrapingCluster: PropTypes.func.isRequired,
}

export default MenuScrapingClusterRerunFlowStepsIndicator
